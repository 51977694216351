.footer {
    position: relative;
    display: grid;
    grid-template-columns: 200px 30px 30px;
    column-gap: 3%;
    height: 8vh;
    align-items: center;
    justify-content: center;
    background-color: #001303;
    color: #cccccc;
    font-size: 18px;
  }