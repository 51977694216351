.palms {
    background: url('../assets/main-bogdan-reeds.jpg') 0 0 no-repeat;
    background-size: cover;
    padding-bottom: 65.89%;
    height: 0;
    text-align: center; 
}


.review {
      margin: 5%;
      text-align: center;
}

.quote {
      font-size: 30px;
      font-style: italic;
}

.sig {
      font-size: 20px;
      font-style: normal;
}