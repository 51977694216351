* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'EB Garamond', serif;
    color: white;
  }
  
  body {
    overflow-x: hidden;
  }
