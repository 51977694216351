.label {
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
    margin-left: 5%;
    margin-right: 5%;
}

.order-desc {
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 5%;
    font-size: 20px;
}

.center {
    text-align: center;
}

.text-to-copy {
    text-align: left;
    font-family:Arial, Helvetica, sans-serif;
    color: black;
    font-size: 14px;
}

.div-with-copy-text {
    background-color: white;
}