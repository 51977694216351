.reed-care-box {
    margin-top: 5%;
    margin-bottom: 5%;
    margin-right: 15%;
    margin-left: 15%;
    font-size: 25px;
}

.topic {
    text-align: center;
}

.do-dont-grid {
    display: grid;
    grid-template-columns: 50% 50%;
    text-align: center;
    justify-content: center;
    margin: 10%;
    font-size: 25px;
    column-gap: 40px;
}

.do {
    text-align: left;
}

.dont {
    text-align: left;
}

.fa-check {
    color: rgb(5, 255, 5);
}

.fa-times-circle {
    color: red;
}