.img-container {
    justify-content: center;
    align-items: center;
    margin: 5%;
    text-align: center;
}

.about-container {
    margin: 7%;
    font-size: 25px;
}

.about-grid {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 10px;
    margin: 10%;
}

.about-grid-item {
    font-size: 25px;
}

.about-grid-pic {
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 960px) {
    .picture {
        width: 300px;
        height: 300px;
    }
}

@media screen and (max-width: 700px) {
    .picture {
        margin-top: 80px;
        width: 200px;
        height: 200px;
    }


}
   
